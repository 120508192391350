.menu {
    &__header {
        height: 156px;
    }

    &__toolbar {
        height: 156px;
        padding-left: 24px;
    }

    &__avatar {
        width: 64px;
        height: 64px;
        margin: 24px 0 30px 0;
    }

    &__icon {
        float: right;
        margin-right: 20px;
        margin-top: 3px;
    }

    &__title {
        width: 100%;
        display: block;
    }

    &__item-icon {
        font-size: 20px;
        margin-right: 30px;
        color: var(--ion-text-color);
    }

    &__item-text {
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        color: var(--ion-text-color);
        cursor: pointer;
    }
}
