.app {
    font-size: 20px;

    ion-button {
        font-size: 16px;
        font-weight: 700;
        text-transform: none;
        height: 3.5em;
        --border-radius: 5px;
        margin: 0;

        &::part(button) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.navigation {
    &__search-icon {
        float: right;
        font-size: 24px;
        margin: 4px 20px;
    }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 11px;
}

::-webkit-scrollbar:horizontal {
    height: 11px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}

::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
}

.overflow-scroll {
    overflow-y: auto !important;
}

hr {
    background: #b0aaaa;
}

html {
    font-size: 20px;
}
