.content {
    text-decoration: none;
    width: 100%;

    ion-row + ion-row {
        border-top: 1px solid #5d5d5d;
    }

    &__title {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
        display: flex;
        align-items: center;

        ion-badge {
            margin-left: 5px;
        }
    }

    &__description {
        font-size: 0.8rem;
        margin: 0;
    }
}
