.divider-col {
    display: flex;
    justify-content: center;
    align-items: center;

    ion-label {
        font-size: 2rem;
    }
}

.ion-col {
    align-self: center;
}

.heading-1 {
    font-size: 1.6rem;
}
