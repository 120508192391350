.header {
    height: 148px;
    background-color: #fff;

    &__left-side {
        margin-left: 30px;
    }

    &__doctor-name {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
    }

    &__seal-number {
        display: block;
        font-size: 14px;
        margin: 0;
    }

    &__event-details {
        text-align: right;
        margin-right: 20px;

        h2 {
            font-size: 18px;
            font-weight: bold;
            margin: 0;
        }

        p {
            font-size: 14px;
            margin: 0;
        }
    }

    &__event-logo {
        margin-right: 15px;
    }
}
