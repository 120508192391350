.statistics {
  &__category-label {
    text-transform: capitalize;
  }
  &__category-table {
    border-collapse: collapse;
    border: 1px solid #000000;
    margin-bottom: 1rem;
    width: 100%;

    td {
      padding: 5px;
      white-space: initial;
      border: 1px solid #000000;

      &:nth-child(1) {
        width: 70%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 15%;
      }
    }

    thead {
      td {
        color: #3171e0;
      }
    }
  }
}