.input-item {
  width: 100%;
  margin-bottom: 20px;

  &.button-shrink {
    ::part(button) {
      padding: 0 3px;

    }
  }

  &--disabled {
    ion-label,
    ion-icon {
      opacity: .3;
    }
  }

  ion-input,
  ion-select,
  ion-textarea {
    background: white;
    border: 1px solid #808080;
    border-radius: 5px;
    font-size: 1rem;
  }

  ion-input,
  ion-textarea {
    padding: 8px 12px !important;
  }

  ion-select {
    padding: 16px 12px !important;
  }

  ion-item {
    --highlight-background: none;
    --border-color: transparent;
    overflow-y: visible;
  }

  ion-radio-group {
    ion-item {
      --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
      padding-right: 17px;
    }
  }

  ion-label {
    transform: none !important;
    font-weight: 600;
    margin-bottom: 5px !important;
    white-space: normal !important;
    overflow: visible !important;
    font-size: 1rem !important;
  }

  ion-icon {
    margin-right: 0 !important;
  }

  ion-radio {
    --color-checked: var(--ion-color-success);
  }

}

.invalid {
  color: red !important;

  ion-label {
    --color: red !important;
  }
}

.overflow-visible {
  ion-item {
    overflow: visible;
  }
}