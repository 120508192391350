.control-buttons {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-column-gap: 30px;
    justify-content: end;
    justify-items: start;

    &--three-column {
        grid-template-columns: 1fr 150px 150px;
    }

    &--four-column {
        grid-template-columns: 1fr auto auto auto;
    }

    ion-button {
        --padding-start: 40px;
        --padding-end: 40px;
        min-width: 150px;
        white-space: normal;
    }
}
