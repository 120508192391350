.text-editor {
  width: 100%;

  .ql-container {
    background-color: #ffffff;
    height: auto;
  }

  .ql-formats .ql-divider {
    &:after {
      content: 'hr'
    }
  }
}