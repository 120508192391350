/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
    font-family: 'Arial Narrow';
    src: url('../assets/fonts/ArialNarrow-Bold.eot');
    src: url('../assets/fonts/ArialNarrow-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/ArialNarrow-Bold.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow-Bold.woff') format('woff'),
    url('../assets/fonts/ArialNarrow-Bold.ttf') format('truetype'),
    url('../assets/fonts/ArialNarrow-Bold.svg#ArialNarrow-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('../assets/fonts/ArialNarrow.eot');
    src: url('../assets/fonts/ArialNarrow.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/ArialNarrow.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow.woff') format('woff'),
    url('../assets/fonts/ArialNarrow.ttf') format('truetype'),
    url('../assets/fonts/ArialNarrow.svg#ArialNarrow') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('../assets/fonts/ArialNarrow-BoldItalic.eot');
    src: url('../assets/fonts/ArialNarrow-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/ArialNarrow-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow-BoldItalic.woff') format('woff'),
    url('../assets/fonts/ArialNarrow-BoldItalic.ttf') format('truetype'),
    url('../assets/fonts/ArialNarrow-BoldItalic.svg#ArialNarrow-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('../assets/fonts/ArialNarrow-Italic.eot');
    src: url('../assets/fonts/ArialNarrow-Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/ArialNarrow-Italic.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow-Italic.woff') format('woff'),
    url('../assets/fonts/ArialNarrow-Italic.ttf') format('truetype'),
    url('../assets/fonts/ArialNarrow-Italic.svg#ArialNarrow-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #014489;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #1B9B57;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #f5c84e;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #d42a2a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    /*Custom*/
    --ion-text-color: #333333;
    --ion-font-family: "Arial Narrow";
    --ion-background-color: #F2F2F2;
    --ion-background-color-rgb: 244, 244, 244;
    --app-border-radius: 5px;
    --app-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

ion-spinner {
    width: 50px;
    height: 50px;
}


ion-alert {
    --max-width: 80% !important;
    --min-width: 80% !important;

    @media (min-width: 1200px) {
        --max-width: 600px !important;
        --min-width: 600px !important;
    }
}
