.ion-list {
  margin-bottom: 50px;
  padding: 0 5px;

  ion-item {
    --ion-item-background: #fff;
    margin: 0 0 15px 0;
    text-decoration: none;
    background: white;
    padding: 10px 0;
    border-radius: var(--app-border-radius);
    box-shadow: var(--app-box-shadow);
  }
}

.add-button {
  position: fixed;
  --ion-safe-area-right: 20px;
  bottom: 20px;

  ion-icon {
    --ion-color-success: #1B9B57;
    font-size: 36px;
  }
}

.content {
  text-decoration: none;
  width: 100%;

  ion-row + ion-row {
    border-top: 1px solid #5d5d5d;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    display: flex;

    ion-badge {
      margin-left: 5px;
    }
  }

  &__description {
    font-size: 12px;
    margin: 0;
  }

  .button {
    font-size: 0.75rem;

    &:first-of-type {
      margin-left: 10px;
    }
  }
}

.icons {
  font-size: 22px;
  margin: 7px 16px;
  color: #333333;
  transition: all 300ms;
  cursor: pointer;

  &--pull-right {
    float: right;
  }

  &--rotate-180deg {
    transform: rotate(180deg);
  }
}

.link {
  cursor: pointer;
}

